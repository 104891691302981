.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
}

.not-found-container .not-found-message {
  vertical-align: middle;
  font-size: 3em;
}
